@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cuisines-list {
  padding: $padding-default 0 0;

  @include mq('tablet') {
    @include page-container;

    margin-bottom: $space-green;
    margin-top: $margin-default;
  }

  &__header {
    @include section-title-header-home;

    padding: 0 $padding-default 26px;
  }

  &__title {
    @include section-title-home;
  }

  &__big-title {
    display: none;
    color: #595756;
    font-size: rem(32);
    font-weight: 500;
    margin: 0;

    @include mq('tablet') {
      display: block;
    }
  }

  &__container {
    min-height: 140px;

    .restaurant-simple-item {
      width: 120px;
    }

    @include mq('tablet') {
      padding: 0 $padding-default-desktop;
    }
  }

  &__button {
    justify-content: flex-end;
    padding-right: 0;
  }

  .cuisine-item {
    display: inline-block;
    text-align: center;
    transition: color $animation-duration $ease-out-circ;

    a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        .cuisine-item__title {
          color: $text-color-super-darken;
        }
      }
    }

    &__figure {
      margin: 0;
      border-radius: 10%;
      width: 80px;
      height: 80px;
      overflow: hidden;
      position: relative;
      z-index: 1;
      transition: transform ease-out 0.2s;
      transform: scale(0.95);

      &:hover {
        transform: scale(1);
        transition: transform ease-out 0.2s;
      }

      @include mq('desktop-s') {
        width: 130px;
        height: 130px;
      }

      img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 0;
        width: 100%;
      }

      @include mq('desktop-s') {
        margin: 0 auto;
      }
    }

    &__title {
      text-align: center;
      display: block;
      font-size: rem(16);
      font-weight: 300;
      line-height: rem(18);
      color: $text-color;
    }
  }

  .carousel__slide {
    display: grid;
    justify-content: center;
  }

  .carousel__controller {
    &--left {
      transform: translateY(-40px) rotateZ(90deg);

      @include mq('desktop-s') {
        left: -25px;
      }
    }

    &--right {
      transform: translateY(-40px) rotateZ(-90deg);

      @include mq('desktop-s') {
        right: -25px;
      }
    }
  }
}
