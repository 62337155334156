@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-cuisines-list {
  .cuisines-list__header {
    .title {
      font-size: rem(18);
      color: $black-color;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }

  .cuisines-list {
    height: auto;
    min-height: auto;

    &__container {
      min-height: 90px;
    }

    .marmita-scrollbox {
      height: auto;

      &__wrapper {
        padding-bottom: 7px;
      }
    }
  }

  .cuisine-item {
    &__figure {
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%);
    }

    &__title {
      text-align: center;
      font-size: rem(16);
      font-weight: normal;
      color: $black-color;
    }
  }
}
