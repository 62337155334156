@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.follow-order-card {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid $light-grey-color;
  color: $text-color;
  display: flex;
  position: relative;
  text-decoration: none;
  width: 100vw;
  padding: 13px $padding-default;

  // Avoiding show FollowOrderCard in mobile to keep less itens on the screen.
  @include mq('desktop-s', max) {
    display: none;
    visibility: hidden;
  }

  @include mq('tablet') {
    padding: 13px $padding-default-desktop;
  }

  @include mq('desktop-s') {
    background: #fff;
    border: 1px solid $light-grey-color-300;
    width: 400px;
    height: 80px;
    border-radius: $border-radius-default;
    padding: 18px $padding-default 17px;
    box-shadow: 2px 2px 14px rgb(0 0 0 / 10%);
  }

  &__icon-order {
    flex-basis: 17px;
    height: 20px;
    margin-right: 15px;
    position: relative;
    transform: translateY(5px);

    @include colorize-icon($text-color-super-darken);

    @include mq('desktop-s') {
      margin: 0 22px 0 2px;
      transform: translateY(0);
    }

    &::after {
      background: $primary-color;
      border-radius: 100%;
      border: 2px solid $almost-white-color;
      bottom: -3px;
      content: '';
      height: 8px;
      position: absolute;
      right: -6px;
      width: 8px;
    }
  }

  &__title {
    font-size: rem(14);
    margin: 0 5px 0 0;
    color: $text-color;

    @include mq('desktop-s') {
      font-size: rem(16);
      font-weight: 300;
      margin-bottom: 7px;
      margin-top: 0;
    }
  }

  &__restaurant {
    color: $text-color-super-darken;
    font-size: rem(14);
    margin: 0;
    font-weight: 500;

    @include mq('desktop-s') {
      font-size: (16px);
    }
  }

  &__icon-arrow {
    @include colorize-icon($medium-grey-color);

    margin-left: auto;
    transform: translate(10px, 7px) rotate(-90deg);
    width: 28px;

    @include mq('desktop-s') {
      width: 34px;
      transform: translate(10px, 0) rotate(-90deg);
    }
  }
}
