@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-sign-up-voucher {
  display: grid;
  grid-column-gap: 12px;
  padding: $padding-default 16px;
  background: #fff;
  border: 1px solid $light-grey-color-200;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  border-radius: 4px;
  margin: 15px $padding-default;
  grid-template-columns: 54px auto 15px;
  width: calc(100% - 40px);

  @include mq('mobile') {
    padding: $padding-default;
    grid-column-gap: 20px;
  }

  @include mq('desktop-s') {
    width: 100%;
    max-width: 333px;
    margin: 15px auto;
  }

  &--hint {
    display: none;
    margin: 0;

    @include mq('desktop-s') {
      display: grid;
    }
  }

  &__icon {
    width: 54px;
    height: 54px;
  }

  &__texts {
    display: grid;
    grid-template-rows: 24px 48px 20px;

    p {
      margin: 0;
    }

    &__title {
      font-size: rem(14);
      line-height: rem(20);
      color: $text-color-super-darken;
      font-weight: 500;
      padding-bottom: 4px;
    }

    &__description {
      font-size: rem(14);
      line-height: rem(16);
      color: $text-color;
      font-weight: 400;
      padding-bottom: 13px;
    }

    &__cta {
      padding: 0;
      margin: 0;
      height: 20px;

      .btn__label {
        text-align: left;
        font-size: rem(14);
      }
    }
  }

  &__close {
    display: none;
    width: 15px;
    left: -2px;
    top: -14px;
    margin: 0;

    @include mq('desktop-s') {
      display: grid;
    }
  }

  &--card {
    display: grid;

    @include mq('mobile', max) {
      width: calc(100% - 40px);
      grid-template-columns: 46.4px auto;
      grid-auto-flow: column;
    }

    @include mq('desktop-s') {
      display: none;
    }
  }
}
