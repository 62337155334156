@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-navbar-wrapper {
  width: 100%;

  &__button {
    position: absolute;
    right: 15px;
    z-index: $hint-z-index;
    margin: 0;
    top: 13px;

    @include mq('tablet') {
      right: 7px;
    }
  }

  @include mq('desktop-s') {
    display: grid;
    justify-content: right;
    max-width: $desktop-container;
    padding: 0 30px;

    &__button {
      display: none;
    }
  }

  .landing-navbar {
    display: grid;
    grid-template-rows: auto repeat(4, 40px);
    grid-row-gap: 20px;
    padding: $padding-default $padding-default 38px;
    justify-content: left;
    z-index: $header-z-index;
    width: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    background: #fff;

    .icon-brand {
      display: grid;
      margin-bottom: 18px;
      height: 32px;

      svg {
        width: auto;
      }
    }

    .navbar-btn {
      display: grid;
    }

    .navbar-btn:hover {
      color: $primary-color;
    }

    transition: all ease-in-out 200ms;

    &--open {
      position: absolute;
      left: 0;
      top: 0;

      .icon-brand {
        display: grid;
        width: 60px;
        margin-bottom: 18px;
      }

      .navbar-btn {
        display: grid;
      }
    }

    @include mq('desktop-s') {
      grid-template-columns: repeat(5, auto);
      grid-template-rows: 50px;
      grid-column-gap: 44px;
      padding: 0;
      padding-top: 16px;
      background: none;
      position: relative;
      top: unset;
      left: unset;

      .icon-brand {
        display: none;
      }
    }

    .btn.navbar-btn {
      padding: 0;
      font-weight: 400;
      height: 40px;
      margin: 0;
      justify-content: start;

      @include mq('desktop-s') {
        justify-content: center;
        height: 50px;
        display: grid;
      }

      &--sign-in {
        color: $primary-color;
        background: none;

        @include mq('desktop-s') {
          border: 1px solid $light-grey-color-300;
          width: 140px;
          font-size: rem(16);
          background: #fff;

          &:hover {
            background: $primary-color;
            color: #fff;
          }
        }
      }
    }
  }

  &--open {
    margin-top: 158px;

    @include mq('desktop-s') {
      margin-top: 0;
    }
  }

  .overlay {
    background: $text-color-super-darken;
    transition: opacity ease-in-out 200ms;
    transition-delay: 200ms;

    &--visible {
      transition-delay: 0ms;
      opacity: 0.6;
    }

    @include mq('desktop-s') {
      display: none;
    }
  }
}
