@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-header-tags {
  max-width: calc(100vw - 40px);
  padding-bottom: 18px;

  @include mq('desktop-s') {
    width: 766px;
    padding: 0;
  }

  &__tag {
    height: 30px;
    background: none;
    border: 1px solid $light-grey-color-300;
    padding: 6px;
    margin: 0;

    &:hover:enabled {
      background: color.adjust(#f5f3f4, $lightness: 5%);
    }

    .btn__label {
      font-size: rem(14);
      line-height: rem(16);
      font-weight: 400;
      color: $text-color;
    }
  }

  .marmita-scrollbox {
    height: auto;

    &__container {
      & > * {
        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
