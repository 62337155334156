@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.highlights-carousel {
  @include mq('tablet') {
    padding: 0 $padding-default;
  }

  @include mq('desktop-s') {
    @include page-container;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }

  &__figure {
    height: 140px;
    margin: 0;
    overflow: hidden;
    position: relative;
    border-radius: $border-radius-default;

    @include mq('tablet') {
      height: 200px;
    }

    img {
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: none;

      @include mq('desktop-s') {
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: none;
      }
    }
  }

  &__wrapper {
    width: 100%;
    padding: 0;
    margin-bottom: $space-blue;

    @include mq('desktop-s') {
      margin-bottom: $space-green;
    }
  }

  .carousel__controller {
    &--left {
      transform: translateY(-32px) rotateZ(90deg);

      @include mq('tablet') {
        transform: translateY(-18px) rotateZ(90deg);
      }

      @include mq('desktop-s') {
        left: -25px;
      }
    }

    &--right {
      transform: translateY(-32px) rotateZ(-90deg);

      @include mq('tablet') {
        transform: translateY(-18px) rotateZ(-90deg);
      }

      @include mq('desktop-s') {
        right: -25px;
      }
    }
  }
}
