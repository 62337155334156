@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-footer {
  color: $black-color;
  padding: $padding-default;

  &__title {
    font-size: rem(14);
    font-weight: bold;
    line-height: em(18);
  }

  &__item {
    font-size: rem(12);
    font-weight: lighter;
    text-decoration: none;
    line-height: em(17);
    color: $black-color;
  }

  &__section {
    margin-bottom: 24px;
    position: relative;

    li {
      margin-top: 8px;
    }
  }

  &__contact-section {
    display: flex;
    justify-content: space-between;

    .btn-icon {
      height: 36px;
      width: 36px;
      margin: 0 8px;
    }

    .icon-marmita {
      height: 36px;
      width: 36px;
    }
  }

  &__social-section {
    position: absolute;
    right: 12px;
  }

  &__copyrights {
    display: flex;
    flex-direction: column;
    color: $black-color;
    font-size: rem(10);
    font-weight: lighter;
    margin-top: 20px;

    span {
      display: block;
    }
  }

  &__separator {
    border-top: 1px solid $iron-color;
    margin-bottom: 24px;
  }

  a:visited {
    color: $black-color;
  }

  a:hover {
    text-decoration: underline;
    color: $black-color;
  }

  a:active {
    color: $black-color;
  }
}
