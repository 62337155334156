@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-header {
  display: grid;
  justify-items: center;
  background: #f5f3f4;
  position: relative;

  @include mq('desktop-s', max) {
    height: calc(100vh + 30px);
    max-height: 690px;
    min-height: 550px;
    grid-template-rows: 1fr 485px;
  }

  &__content {
    display: grid;
    padding: 0 $padding-default;
    justify-items: center;
    z-index: 1;
    width: 100%;
    grid-row-gap: 18px;

    @include mq('tablet') {
      max-width: $desktop-container;
      padding: 0;
      margin: 30px 0;
      grid-template-rows: 124px 40px 25px 50px 30px;
      grid-row-gap: 30px;
    }

    @include mq('desktop-s') {
      padding: 0 30px;
    }
  }

  &--logo {
    display: grid;
    width: 100%;
    margin: 30px 0;

    @include mq('desktop-s') {
      margin: 0;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .icon-brand {
      height: 50px;
      align-self: center;
      justify-self: center;

      @include mq('tablet') {
        height: 68px;
      }

      svg {
        width: auto;
      }

      @include mq('desktop-s') {
        align-self: end;
        grid-column: 2;
      }
    }

    .landing-sign-up-voucher {
      @include mq('desktop-s') {
        grid-column: 3;
        justify-self: end;
      }
    }
  }

  &--title {
    color: $text-color-super-darken;
    font-weight: 500;
    margin: 0;
    text-align: center;
    letter-spacing: -1px;
    line-height: rem(33);
    font-size: rem(33);

    @include mq('desktop-s') {
      letter-spacing: normal;
      line-height: rem(40);
      font-size: rem(40);
    }

    strong {
      color: $primary-color;
    }
  }

  &--subtitle {
    color: $text-color-super-darken;
    text-align: center;
    line-height: rem(22);
    font-size: rem(18);
    font-weight: 400;
    margin: 0;

    @include mq('desktop-s') {
      line-height: rem(25);
      font-size: rem(20);
    }
  }

  &__btn-wrapper {
    display: grid;
    grid-template-rows: repeat(2, 50px);
    grid-row-gap: 18px;
    width: 100%;

    @include mq('tablet') {
      grid-template-rows: 50px;
      grid-template-columns: auto 168px;
      grid-column-gap: 18px;
      max-width: calc(100vw - 40px);
    }

    @include mq('desktop-s') {
      max-width: 766px;
    }

    .bar-btn {
      background: #fff;
      padding: 14px 0 14px 14px;
      width: 100%;
      margin: 0;
      font-weight: 300;
      box-shadow: 0 4px 16px rgb(0 0 0 / 8%);

      .btn__icon {
        width: 25px;
        height: 25px;

        @include colorize-icon($primary-color);
      }

      .btn__label {
        text-align: left;
        font-size: rem(16);
        line-height: rem(20);
        font-weight: 400;
        margin: 0;
        color: $text-color-lighten;
        margin-left: 6px;

        @include mq('mobile-l') {
          margin-left: 26px;
        }
      }

      &:hover:enabled {
        background: #fff;
      }
    }

    .search-btn {
      height: 50px;
      margin: 0;
      font-weight: 500;

      @include mq('desktop-s') {
        width: 168px;
      }

      .btn__label {
        font-size: rem(14);
        line-height: rem(20);
      }
    }
  }
}
