@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-header-background {
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 0;
  z-index: 0;
  pointer-events: none;

  &__left {
    img {
      position: absolute;
      width: 300px;
      left: -60px;
      top: calc(100% - 624px);
    }

    svg {
      position: absolute;
      width: 110px;
      height: 120px;
      right: 14px;
      top: calc(100% - 500px);
    }

    @include mq('mobile-l') {
      img {
        width: 340px;
        left: 0;
        top: 0;
      }

      svg {
        width: 140px;
        height: 150px;
        left: 30px;
        top: 170px;
        right: unset;
      }
    }

    @include mq('desktop') {
      img {
        width: 400px;
        left: 0;
      }

      svg {
        width: 160px;
        height: 180px;
        left: 71px;
        top: 257px;
      }
    }

    @include mq('desktop-wide') {
      img {
        width: 439px;
        left: 0;
      }

      svg {
        width: 180px;
        height: 200px;
        left: 71px;
        top: 257px;
      }
    }
  }

  &__right {
    display: none;

    &--image {
      position: absolute;
      width: 160px;
      right: -30px;
      top: 140px;
    }

    svg {
      position: absolute;
      transform: rotate(-8.34deg);
      top: 40px;
      right: 30px;
      width: 150px;
    }

    @include mq('mobile-l') {
      display: block;

      &--image {
        width: 210px;
        right: -30px;
        top: 140px;
      }

      svg {
        top: 70px;
        right: 110px;
        width: 180px;
        height: 170px;
      }
    }

    @include mq('desktop') {
      &--image {
        width: 240px;
        right: -1px;
        top: 204px;
      }

      svg {
        top: 106px;
        right: 138px;
        width: 198px;
        height: 172px;
      }
    }

    @include mq('desktop-wide') {
      &--image {
        width: 269px;
        right: -1px;
        top: 204px;
      }

      svg {
        top: 106px;
        right: 138px;
        width: 198px;
        height: 172px;
      }
    }
  }
}
